import React, { Fragment } from 'react';

import Link from 'next/link';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaGlobe } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

import { AppLogo, EventName, trackEvent } from '@mwell-healthhub/commons';

import { quickLinks, supportLinks } from '../../../constants/footerLinks';
import { QuickLink } from '../../../types/quick-link';

const appStoreImageUrl = '/static-assets/images/app-store.webp';
const googlePlayImageUrl = '/static-assets/images/google-play.webp';

type Props = {
  containerClassName?: string;
};

const Footer = (props: Props) => {
  const { containerClassName } = props;
  const columns: QuickLink[][] = [];
  let currentColumn: QuickLink[] = [];

  quickLinks.forEach((link, index) => {
    if (index % 5 === 0 && index !== 0) {
      columns.push(currentColumn);
      currentColumn = [];
    }
    currentColumn.push(link);
  });

  if (currentColumn.length > 0) {
    columns.push(currentColumn);
  }

  function handleSupportLink(support: string) {
    trackEvent(EventName.CLICK_SUPPORT, {
      support,
    });
  }

  return (
    <Fragment>
      <div
        className={twMerge(
          'w-full overflow-x-hidden bg-primary-100 bg-opacity-10 py-20',
          containerClassName,
        )}
      >
        <div className="mx-auto grid grid-cols-2 grid-rows-2 items-start gap-x-12 gap-y-10 px-12 sm:justify-start md:justify-between md:gap-x-5 lg:flex lg:flex-row xl:px-[75px] min-[1440px]:w-[1440px]">
          <div className="order-1 flex flex-col gap-y-4 lg:order-1 lg:gap-y-[26px]">
            <AppLogo height={24} />
            <div className="flex gap-x-6">
              <Link href="https://www.facebook.com/mwellph/" target="_blank">
                <FaFacebook className="text-[24px] text-primary-500" />
              </Link>
              <Link href="https://www.instagram.com/mwellph/?hl=en" target="_blank">
                <FaInstagram className="text-[24px] text-primary-500" />
              </Link>
              <Link href="https://twitter.com/mWellph" target="_blank">
                <FaTwitter className="text-[24px] text-primary-500" />
              </Link>
              <Link
                href="https://ph.linkedin.com/company/metro-pacific-health-tech-mwell"
                target="_blank"
              >
                <FaLinkedin className="text-[24px] text-primary-500" />
              </Link>
              <Link href="https://www.mwell.com.ph/" target="_blank">
                <FaGlobe className="text-[24px] text-primary-500" />
              </Link>
            </div>
            <p className="text-sm text-primary-500">support@mwell.com.ph</p>
            <p className="text-sm text-primary-500">
              9/F, Rockwell Business Center Tower 1,
              <br />
              Ortigas Ave., Ortigas Center, Brgy. Ugong,
              <br />
              1604, Pasig City, Philippines
            </p>
          </div>
          <div className="order-3 flex flex-col gap-y-2 lg:order-2 lg:gap-y-[18px]">
            <h3 className="text-base font-semibold text-primary-500 lg:text-lg">Quick Links</h3>
            <div className="flex gap-x-12 xl:gap-x-20">
              {columns.map((column, colIndex) => (
                <div key={colIndex} className="flex flex-col sm:gap-y-4 md:gap-y-2 lg:gap-y-[18px]">
                  {column.map((link, linkIndex) => (
                    <Link key={linkIndex} href={link.url} className="text-sm text-primary-500">
                      {link.text}
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="order-4 flex flex-col gap-y-2 lg:order-3 lg:gap-y-[18px]">
            <h3 className="text-base font-semibold text-primary-500 lg:text-lg">Support</h3>
            <div className="flex flex-col sm:gap-y-4 md:gap-y-2 lg:gap-y-[18px]">
              {supportLinks.map((link, index) => (
                <Link
                  key={index}
                  href={link.url}
                  className="text-sm text-primary-500"
                  target="_blank"
                  onClick={() => handleSupportLink(link.text)}
                >
                  {link.text}
                </Link>
              ))}
            </div>
          </div>
          <div className="order-2 flex flex-col gap-y-2 lg:order-4 lg:gap-y-[18px]">
            <h3 className="text-base font-semibold text-primary-500 lg:text-lg">Download</h3>
            <div className="flex gap-x-5 gap-y-2 md:flex-col lg:gap-x-0 lg:gap-y-[9px]">
              <Link
                href="https://apps.apple.com/ph/app/mwell-ph-24-7-doctor-consult/id1540729485?mt=8"
                target="_blank"
              >
                <img src={appStoreImageUrl} alt="App Store" width={140} height={51} />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.lf.mpicph&pcampaignid=web_share"
                target="_blank"
              >
                <img src={googlePlayImageUrl} alt="Google Play" width={140} height={51} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-12 w-full items-center justify-center bg-primary-500">
        <p className="text-base font-medium text-white">
          mWell HealthHub © All rights reserved Copyright 2023
        </p>
      </div>
    </Fragment>
  );
};

export default Footer;
