import { useCallback, useEffect, useState } from 'react';

import {
  LocalStorageKey,
  computeDistance,
  setLocalStorageItem,
  toast,
  truncateCoordinate,
} from '@mwell-healthhub/commons';

import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DISTANCE_REFRESH_THRESHOLD,
  REFRESH_POSITION_IN_MS,
} from '../../constants';

export function useGeoLocation(params: { refreshInMs: number } = { refreshInMs: 0 }) {
  const [geoLocation, setGeoLocation] = useState<{ latitude: number; longitude: number } | null>(
    null,
  );
  const [error, setError] = useState<string | null>(null);
  const [geoLocationState, setGeoLocationState] = useState<string | null>(null);

  const getGeolocationStateAndSet = useCallback(async () => {
    if ('permissions' in navigator) {
      try {
        const result = await navigator.permissions.query({ name: 'geolocation' });

        setGeoLocationState(result.state);

        return result.state;
      } catch (error) {
        console.error('Error fetching geolocation state:', error);

        return null;
      }
    }

    return null;
  }, []);

  const getGeoLocation = useCallback(async () => {
    setError(null); // Reset error before attempting to fetch again

    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      setGeoLocation({ latitude, longitude });

      getGeolocationStateAndSet();

      return { latitude, longitude };
    } catch (error) {
      setError('Error fetching geolocation: ' + (error as Error).message);
    }

    return null;
  }, [getGeolocationStateAndSet]);

  const getGeoLocationInstructions = () => {
    if (geoLocationState === 'denied') {
      const instructions = showGeolocationInstructions();

      return toast({
        message: instructions,
        type: 'warning',
        alertPosition: 'top-center',
      });
    } else {
      return toast({
        message: 'Please allow location access in your browser to enable this feature.',
        type: 'warning',
        alertPosition: 'top-center',
      });
    }
  };

  const showGeolocationInstructions = () => {
    const browser = getBrowserName();
    let instructions = '';

    if (browser === 'chrome') {
      instructions =
        "To enable geolocation for this site in Google Chrome, please follow these steps:\n\n1. Click on the three dots in the upper-right corner of the browser.\n2. Select 'Settings'.\n3. Scroll down and click on 'Privacy and security'.\n4. Click on 'Site Settings'.\n5. Under 'Permissions', click on 'Location'.\n6. Find this website and change the setting to 'Allow'.\n7. Reload the page to use geolocation.";
    } else if (browser === 'safari') {
      instructions =
        "To enable geolocation for this site in Safari, please follow these steps:\n\n1. Click on 'Safari' in the top menu bar.\n2. Select 'Preferences'.\n3. Go to the 'Websites' tab.\n4. Select 'Location' from the left sidebar.\n5. Find this website and change the setting to 'Allow'.\n6. Close the Preferences window and reload the page to use geolocation.";
    } else if (browser === 'firefox') {
      instructions =
        "To enable geolocation for this site in Mozilla Firefox, please follow these steps:\n\n1. Click on the three horizontal lines in the upper-right corner of the browser.\n2. Select 'Options'.\n3. Go to the 'Privacy & Security' tab.\n4. Scroll down to the 'Permissions' section.\n5. Click on 'Settings' next to 'Location'.\n6. Find this website and change the setting to 'Allow'.\n7. Close the Options tab and reload the page to use geolocation.";
    }

    return instructions;
  };

  const getBrowserName = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf('chrome') > -1) {
      return 'chrome';
    } else if (userAgent.indexOf('safari') > -1) {
      return 'safari';
    } else if (userAgent.indexOf('firefox') > -1) {
      return 'firefox';
    }

    return '';
  };

  useEffect(() => {
    // Run initially
    getGeoLocation();

    const interval = setInterval(() => {
      getGeoLocation();
    }, params.refreshInMs);

    return () => {
      clearInterval(interval);
    };
  }, [params.refreshInMs]);

  return {
    error,
    geoLocation,
    getGeoLocation,
    getGeoLocationInstructions,
  };
}

export function useGetNewPosition() {
  const { error, geoLocation } = useGeoLocation({
    refreshInMs: REFRESH_POSITION_IN_MS,
  });

  const { selectedLatitude, selectedLongitude } = getTruncatedPosition(error, geoLocation);
  const position = localStorage.getItem(LocalStorageKey.POSITION)?.replace(/"/gi, '') ?? '';

  useEffect(() => {
    if (position || !selectedLongitude || !selectedLatitude) {
      const [positionLongitude, positionLatitude] = position.split(',').map(Number);
      const distanceFromPreviousPosition = computeDistance(
        positionLatitude,
        positionLongitude,
        selectedLatitude as number,
        selectedLongitude as number,
      );

      if (
        Number.isNaN(distanceFromPreviousPosition) ||
        distanceFromPreviousPosition < DISTANCE_REFRESH_THRESHOLD
      ) {
        return;
      }
    }

    const newPosition = [selectedLongitude, selectedLatitude].join(',');

    setLocalStorageItem(LocalStorageKey.POSITION, newPosition);
  }, [position, selectedLatitude, selectedLongitude]);

  return {
    error,
    position,
  };
}

function getTruncatedPosition(error: string | null, geoLocation: Record<string, number> | null) {
  if (geoLocation && geoLocation.latitude && geoLocation.longitude) {
    return {
      selectedLatitude: truncateCoordinate(geoLocation.latitude),
      selectedLongitude: truncateCoordinate(geoLocation.longitude),
    };
  }

  if (error) {
    return {
      selectedLatitude: truncateCoordinate(DEFAULT_LATITUDE),
      selectedLongitude: truncateCoordinate(DEFAULT_LONGITUDE),
    };
  }

  return {};
}

export function ensureLatitude(lat: number) {
  if (!lat) {
    return DEFAULT_LATITUDE;
  }

  return lat;
}

export function ensureLongitude(long: number) {
  if (!long) {
    return DEFAULT_LONGITUDE;
  }

  return long;
}
