import { HTMLProps, useEffect, useState } from 'react';

import { filter, find, isEqual, sortBy } from 'lodash';

import {
  hmoList,
  MultiSelectDropdownPopup,
  MultiSelectDropdownMobile,
  MultiSelectOptionType,
  clsxMerge,
  useRouter,
} from '@mwell-healthhub/commons';
import useIsMobile from '@mwell-healthhub/commons/hooks/useIsMobile';

import { HmoFilterButton } from '../atoms';

const OPTION_HMO_LIST: MultiSelectOptionType[] = [...hmoList]
  .filter((hmo) => hmo.isActive)
  .sort((hmoA, hmoB) => hmoA.name.localeCompare(hmoB.name))
  .map((hmo) => ({
    text: hmo.name,
    value: hmo.id.toString(),
  }));

const HMO_FILTERS_FIELD_KEY = 'hmoFilters';

type Props = HTMLProps<HTMLDivElement> & {
  appliedHmoFilters: MultiSelectOptionType[];
  handleApplyHmoFilters: (hmoFilters: MultiSelectOptionType[]) => void;
  buttonClassName?: HTMLProps<HTMLElement>['className'];
  badgesClassName?: HTMLProps<HTMLElement>['className'];
};

const HmoFilter = ({
  appliedHmoFilters,
  handleApplyHmoFilters,
  buttonClassName,
  badgesClassName,
  className,
}: Props) => {
  const router = useRouter();
  const { query } = router;
  const [selectedHmoFilters, setSelectedHmoFilters] = useState<MultiSelectOptionType[]>([]);
  const [showHmoFilters, setShowHmoFilters] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const handleUpdateHmoFilters = (selectedHmoFilters: MultiSelectOptionType[]) => {
    setSelectedHmoFilters(selectedHmoFilters);
  };

  const toggleHmoFilterDropdown = () => {
    setShowHmoFilters((prev) => !prev);
  };

  const handleBack = () => {
    toggleHmoFilterDropdown();
    setSelectedHmoFilters(appliedHmoFilters);
  };

  useEffect(() => {
    if (query && query?.hmoFilters) {
      const hmoFilters =
        typeof query.hmoFilters === 'string' ? [query.hmoFilters] : query.hmoFilters;
      const filteredHmoFilters = filter(OPTION_HMO_LIST, ({ value }) => {
        return Boolean(find(hmoFilters, (hmoFilter) => value === hmoFilter));
      });
      if (
        !isEqual(
          sortBy(filteredHmoFilters, ({ text }) => text),
          sortBy(selectedHmoFilters, ({ text }) => text),
        )
      ) {
        handleApplyHmoFilters(filteredHmoFilters);
      }
    }
  }, [query]);

  useEffect(() => {
    setSelectedHmoFilters(appliedHmoFilters);
  }, [appliedHmoFilters]);

  return (
    <>
      {isMobile && (
        <MultiSelectDropdownMobile
          fieldName={HMO_FILTERS_FIELD_KEY}
          options={OPTION_HMO_LIST}
          selectedOptions={selectedHmoFilters}
          onSelect={handleUpdateHmoFilters}
          onRemove={handleUpdateHmoFilters}
          onClearAll={() => handleApplyHmoFilters([])}
          onSubmit={() => handleApplyHmoFilters(selectedHmoFilters)}
          onBack={handleBack}
          onToggleModalState={toggleHmoFilterDropdown}
          modalState={showHmoFilters}
          title="Select HMO"
        />
      )}
      <div className={clsxMerge('relative', className)}>
        {!isMobile && (
          <MultiSelectDropdownPopup
            showSelectedCount
            placeholder="HMO Filter"
            options={OPTION_HMO_LIST}
            selectedOptions={selectedHmoFilters}
            onSelect={handleUpdateHmoFilters}
            onRemove={handleUpdateHmoFilters}
            clearLabel="Clear all"
            onClearAll={() => handleApplyHmoFilters([])}
            onSubmit={() => handleApplyHmoFilters(selectedHmoFilters)}
            className="hidden md:block"
            selectInnerClassName="bg-white border border-black border-opacity-20"
            dropdownClassName="top-full right-0"
            dropdownPopupState={showHmoFilters}
            toggleDropdownPopupState={() => setShowHmoFilters((prev) => !prev)}
          />
        )}
        <HmoFilterButton
          hmoFiltersCount={appliedHmoFilters.length}
          onClick={toggleHmoFilterDropdown}
          className={buttonClassName}
        />
      </div>
    </>
  );
};

export default HmoFilter;
